<template>
  <div class="tops">
    <NavBarFree />
    <div class="tops__body">
      <div role="button" class="back-button" @click="onClickBack">
        <div class="image">
          <img src="/icons/icn_arrow_back.svg" />
        </div>
        <div class="text">Volver</div>
      </div>
      <Tops id="Tops" await-for-season :season="seasonId" :is-cup="isCup" :show-logos="false" :custom="custom" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopsFull',
  components: {
    NavBarFree: () => import('@/components/NavBars/NavBarFree'),
    Tops: () => import('@/views/Tops'),
  },
  data() {
    return {
      seasonId: 0,
      isCup: false,
      custom: {
        logo: 'http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png',
        color: '#cbee6b',
        background: '#242424',
        colorheader: '#132839',
        backgroundheader: '#CCE8B5',
        headerBackground: '#132839',
        headerColor: '#CBEE6E',
        headerBorderColor: '#CBEE6E',
        header2Background: '#132839',
        header2Color: '#FFFFFF',
        subHeaderBackground: '#cbee6b',
        subHeaderColor: '#353535',
        headerBetToolBackground: '#242424',
        subheaderBetToolBackground: '#151515',
      },
    };
  },
  methods: {
    onClickBack() {
      if (this.$route.query.team) {
        this.$router.push(`/widget/${this.$route.query.team}`);
      } else {
        this.$router.push({ name: 'landing' });
      }
    },
  },
  created() {
    const seasonId = Number(this.$route.query.seasonId || 0);
    this.seasonId = Number.isNaN(seasonId) ? 0 : seasonId;
    this.isCup = !!this.$route.query.isCup;
  },
};
</script>

<style scoped lang="scss">
.tops {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__body {
    max-width: 1296px;

    & .back-button {
      width: fit-content;
      padding: 9px 20px;
      margin: 32px 0 32px 8px;
      border-radius: 10px;
      color: white;
      background-color: #132839;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      & .image {
        height: 32px;
        width: 32px;
      }

      & .text {
        margin-left: 17px;
      }
    }
  }
}
</style>
